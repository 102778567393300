import * as React from "react";
import { Link } from "gatsby";

const BreadCrumbs = ({ breadCrumbs }) => {
  return (
    <nav className="rounded-md w-full">
      <ol className="list-reset flex">
        {breadCrumbs?.map((crumb) => {
          if (crumb.href) {
            return (
              <>
                <li key={crumb.href}>
                  <Link to={crumb.href} class="text-blue-600 hover:text-blue-700">
                    {crumb.label}
                  </Link>
                </li>
                <li key={crumb.href}>
                  <span class="text-gray-500 mx-2">/</span>
                </li>
              </>
            );
          } else {
            return (
              <li key={crumb.href} className="text-gray-500">
                {crumb.label}
              </li>
            );
          }
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
