import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BreadCrumbs from "../components/breadCrumbs";

const UpsoldFaq = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Discount Mixer Help" />
        <Bio />
        <p>No help content found.).</p>
      </Layout>
    );
  }
  const postSection = (sectionTitle, posts) => {
    return (
      <div className="max-w-xl container">
        <h1>{sectionTitle}</h1>
        <ol style={{ listStyle: "outside" }}>
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug;

            return (
              <li key={post.fields.slug}>
                <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                  <header>
                    <h5>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h5>
                  </header>
                </article>
              </li>
            );
          })}
        </ol>
      </div>
    );
  };
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Upsold FAQs" />
      <div className="px-8 flex flex-col items-center mt-10">
        <div className="max-w-xl">
          <BreadCrumbs
            breadCrumbs={[
              { href: "/support-center", label: "Support Home" },
              { href: "/upsold-help", label: "Upsold Support" },
              { label: "FAQs" },
            ]}
          />
        </div>
      </div>
      <div className="blog px-8 flex flex-col items-center mt-10">
        {postSection(
          "Troubleshooting",
          posts.filter((p) => p?.fileAbsolutePath?.includes("faqs/troubleshooting"))
        )}
      </div>
    </Layout>
  );
};

export default UpsoldFaq;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/upsold-help/faqs/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        fileAbsolutePath
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
